<template>
    <div class="page">
        <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
            :searchFormData="searchFormData" :showSearch="true" :showAdd="$hasAccess('market.plan/add')" addName="新建"
            @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">

            <!--搜索自定义内容-->
            <el-form-item slot="search-item">
                <el-input v-model="searchFormData.sn_no" size="small" clearable placeholder="相机编号"></el-input>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.group_id" size="small" clearable placeholder="请选择站点">
                    <el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name" :value="item.group_id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item slot="search-item">
                <el-select v-model="searchFormData.plan_type" placeholder="状态" clearable size="small">
                    <el-option label="在线" :value="1"></el-option>
                    <el-option label="离线" :value="2"></el-option>
                </el-select>
            </el-form-item>

            <!--表格内容-->
            <vxe-table-column slot="table-item" field="sn_no" title="相机编号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="park_number" title="对应枪编号" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="group_name" title="所属场站" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="100px" />
            <vxe-table-column slot="table-item" title="操作" align="center" width="160">
                <template v-slot="{ row }">
                    <el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('monitor.rf/edit')">编辑</el-button>
                    <el-button size="small" plain @click="deleteOne(row)">删除</el-button>
                </template>
            </vxe-table-column>
        </Tables>

        <!-- 新增 -->
        <el-dialog title="新增相机" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
            width="500px">
            <el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="100px" class="form">
                <el-form-item label="相机编号" prop="sn">
                    <el-input v-model="addDialogFormData.sn" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="归属场站" prop="plan_type">
                    <el-select v-model="addDialogFormData.group_id"  size="small"   remote
                               reserve-keyword clearable placeholder="请选择站点" v-if="$hasAccess('device.group/view')"
                               :remote-method="remoteGroup" :loading="groupLoading">
                        <el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name"
                                   :value="item.group_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车位号" prop="plan_money">
                    <el-input v-model="addDialogFormData.park_one" style="width: 38%"></el-input>
                    <el-input v-model="addDialogFormData.park_two" style="width: 38%;margin-left: 4%;"></el-input>
                </el-form-item>
                <el-form-item label="枪编号" prop="gift_money">
                    <el-input v-model="addDialogFormData.plug_one" style="width: 38%"></el-input>
                    <el-input v-model="addDialogFormData.plug_two" style="width: 38%;margin-left: 4%;"></el-input>
                </el-form-item>
                <el-form-item label="上传附件" prop="pay_img">
					<upload type="image" :imgWidth="80" :imgHeight="80" :fileList="addDialogFormData.fileInfo" @changeFile="changeFileAdd($event)"></upload>
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
                    v-if="$hasAccess('monitor.rf/add')">保 存
                </el-button>
                <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 编辑 -->
        <el-dialog title="编辑相机" :visible.sync="editDialogShow" :close-on-click-modal="false"
            :close-on-press-escape="false" width="500px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
                <el-form-item label="相机编号" prop="sn_no">
                    <el-input v-model="editDialogFormData.sn_no" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="归属场站" prop="plan_type">
                    <el-select v-model="editDialogFormData.group_id" size="small" filterable reserve-keyword clearable placeholder="请选择站点">
                        <el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name" :value="item.group_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车位号" prop="plan_money">
                    <el-input v-model="editDialogFormData.park_one" style="width: 38%;"></el-input>
                    <el-input v-model="editDialogFormData.park_two" style="width: 38%;margin-left: 4%;"></el-input>
                </el-form-item>
                <el-form-item label="枪编号" prop="gift_money">
                    <el-input v-model="editDialogFormData.plug_one" style="width: 38%;"></el-input>
                    <el-input v-model="editDialogFormData.plug_two" style="width: 38%;margin-left: 4%;"></el-input>
                </el-form-item>
                <el-form-item label="上传附件" prop="pay_img">
					<upload type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.fileInfo" @changeFile="changeFileEdit($event)"></upload>
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存 编 辑
                </el-button>
                <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import Tables from '@/components/tables'
import upload from '@/components/upload'

export default {
    name: 'market-plan-list',
    components: {
        Tables, upload
    },
    data() {
        return {
            // 表格信息
            tableName: '相机管理',
            isLoading: false,
            tableData: [],
            totalPage: 0,
            searchFormData: {},
            // 新增
            addDialogShow: false,
            addDialogFormData: {},
            groupList: [],
            groupIds: [],
            groupLoading:'',
            rules: {
                sn: [
                    { required: true, message: '请输入相机编号', trigger: 'change' }
                ],
                sn_no: [
                    { required: true, message: '请输入相机编号', trigger: 'change' }
                ],
                park_one: [
                    { required: true, message: '请输入1车位', trigger: 'change' }
                ],
                park_two: [
                    { required: true, message: '请输入2车位', trigger: 'change' }
                ],
                plug_one: [
                    { required: true, message: '请输入1枪口', trigger: 'change' }
                ],
                plug_two: [
                    { required: true, message: '请输入2枪口', trigger: 'change' }
                ]
            },
            // 编辑
            editDialogShow: false,
            editDialogFormData: {},
            editDialogFormOriData: {},
            editNeedData: ['plan_id', 'plan_name', 'plan_money', 'plan_type', 'gift_money']
        }
    },
    computed: {
        editDisabled() {
            for (let item of this.editNeedData) {
                if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
                    return false
                }
            }
            return true
        }
    },
    mounted() {
        this.init();
    },
    activated() {
        this.init();
    },
    methods: {
        // 初始化请求
        init() {
            this.remoteGroup();
        },
        // 表格列表
        async getList(currentPage, pageSize) {
            const params = {
                token: this.$store.state.user.token,
                page: currentPage,
                size: pageSize
            }
            this.isLoading = true
            const searchData = this.searchFormData
            const res = await this.$api.Rf.GetRfList(params, searchData);
            this.tableData = res.data;
            for(let v of this.tableData){
                let park_number = JSON.parse(v.park_number);
                let ks = Object.keys(park_number);
                v.park_one = ks[0];
                v.park_two = ks[1];
                v.plug_one = park_number[ks[0]];
                v.plug_two = park_number[ks[1]];
            }
            this.totalPage = res.total
            this.isLoading = false
        },
        // 重置搜索
        clearSearch() {
            this.searchFormData = {}
        },
        async remoteGroup(query) {
            if (query !== '') {
                this.groupLoading = true
                const params = {
                    token: this.$store.state.user.token,
                    page: 1,
                    size: 1000
                }
                const res = await this.$api.Device.GetGroupList(params, {
                    group_name: query
                })
                this.groupList = res.data
                this.groupLoading = false
            } else {
                this.groupList = []
            }
        },
        // 重置form
        resetFormData() {
            this.addDialogFormData = {
                park_one: 1,
                park_two: 2,
            }
            this.editDialogFormData = {}
            this.editDialogFormOriData = {}
            if (this.$refs.addForm) {
                this.$refs.addForm.resetFields()
            }
            if (this.$refs.editForm) {
                this.$refs.editForm.resetFields()
            }
        },
        // 新增
        addOne() {
            this.resetFormData();
            this.addDialogShow = true
        },
        // 编辑
        async editOne(row) {
            this.resetFormData()
            this.editDialogFormData = Object.assign({}, row);
            this.editDialogFormData.fileInfo = [{url:this.editDialogFormData.url}];
            delete this.editDialogFormData._XID;
            this.editDialogShow = true
        },
        // 删除
        deleteOne(row) {
            this.$confirm('确定要删除该信息吗', '提示', {
                cancelButtonClass: 'btn-custom-cancel',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const params = {
                    token: this.$store.state.user.token,
                    id: row.id
                }
                await this.$api.Rf.DelRf(params)
                this.$notify({
                    title: '成功',
                    message: '删除成功',
                    type: 'success'
                })
                this.$refs.xTable.refreshTable()
            })
        },
        // 关闭弹窗
        closeDialog() {
            this.addDialogShow = false
            this.editDialogShow = false
        },
        // 新增保存
        saveAdd() {
            this.$refs.addForm.validate(async valid => {
                if (valid) {
                    const params = {
                        token: this.$store.state.user.token,
                        form: JSON.stringify(this.addDialogFormData)
                    }
                    await this.$api.Rf.AddRf(params)
                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    })
                    this.closeDialog()
                    this.$refs.xTable.refreshTable()
                }
            })
        },
        // 编辑保存
        saveEdit() {
            this.$refs.editForm.validate(async valid => {
                if (valid) {
                    const params = {
                        token: this.$store.state.user.token,
                        form: JSON.stringify(this.editDialogFormData)
                    }
                    await this.$api.Rf.EditRf(params)
                    this.$notify({
                        title: '成功',
                        message: '编辑成功',
                        type: 'success'
                    })
                    this.closeDialog()
                    this.$refs.xTable.refreshTable()
                }
            })
        },
        changeFileAdd(fileInfo){ //上传的文件
            console.log("changeFileAdd", fileInfo);
            this.addDialogFormData.fileInfo = fileInfo;
            this.addDialogFormData.attach_id = this.addDialogFormData.fileInfo[0].attach_id;
            console.log(this.addDialogFormData);
        },
        changeFileEdit(fileInfo){ //上传的文件
            console.log("changeFileEdit", fileInfo);
            this.editDialogFormData.fileInfo = fileInfo;
            this.editDialogFormData.attach_id = this.editDialogFormData.fileInfo[0].attach_id;
            //console.log(this.editDialogFormData);
        },
    }
}
</script>
  
<style lang="scss" scoped></style>
  